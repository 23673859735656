import { useContext, useEffect } from 'react';
// import YouTube from 'react-youtube';
import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url'
import { motion } from 'framer-motion';

import styles from './display.module.scss';
import { variants } from '../../utils/variants';

import { AppContext } from '../../AppContext';

import { ReactComponent as IconBeer } from '../../icons/icon__beer.svg';

const client = createClient({
  projectId: 'u4sc3w5j',
  dataset: 'production',
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: '2023-05-03', // use current date (YYYY-MM-DD) to target the latest API version
})

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source)
}

export default function Display() {

  const { content, language, setUpdate, update } = useContext(AppContext);

  // refresh every 1 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      setUpdate(!update);
      // console.log(update);
      // console.log(content);
    }, 1000 * 120);
    return () => clearInterval(interval);
  }, [update, setUpdate]);

  if (!content.menu) {
    return <div>Loading...</div>;
  }

  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={variants}
      className={styles.container}
    >

      <div className={styles.menu}>

        {content.menu && content.menu.map((_, index) => (

          <div className={styles.menuItem} key={index}>

            {_.soldOut === 'sold-out' && (
              <div className={styles.menuItemSoldOut}>
                <h2>{language === "en-US" ? "Sold Out" : "Agotado"}</h2>
              </div>
            )}

            <div className={styles.menuItemInner}>
              <div className={styles.menuItemImage}>
                {_.logo ? (
                  <img
                    src={urlFor(_.logo.asset._ref).width(200).url()}
                    alt={_.nameES}
                  />
                ) : (
                  <img
                    src={`/map/icon__ship--${random(1, 5)}.png`}
                    alt={_.nameES}
                  />
                )}
              </div>
              <div className={styles.menuContent}>
                <div className={styles.menuItemTitle}>
                  <h2>{language === "en-US" ? _.nameEN : _.nameES}</h2>
                  <span>${_.price}</span>
                </div>
                <p>
                  {language === "en-US" ? _.descriptionEN : _.descriptionES}
                </p>
                {_.abv && (
                  <div className={styles.menuItemMeta}>
                    <div className={styles.menuItemMetaInner}>
                      <IconBeer />
                      {language === "en-US" ? (
                        <span>{_.abv}% ABV</span>
                      ) : (
                        <span>{_.abv}% de alcohol</span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

        ))}

      </div>

    </motion.div>
  );
}