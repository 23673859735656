import React, { useEffect, useRef, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import GoogleMap from 'google-maps-react-markers';

import { variants } from '../../utils/variants';

// import { ReactComponent as IconSteam } from '../../icons/icon__metal.svg';
// import { ReactComponent as IconPin } from '../../icons/icon__pin.svg';
// import { ReactComponent as IconHome } from '../../icons/icon__home.svg';
// import { ReactComponent as IconFood } from '../../icons/icon__food.svg';
// import { ReactComponent as IconMusic } from '../../icons/icon__music.svg';
// import { ReactComponent as IconPlane } from '../../icons/icon__airplane.svg';
// import { ReactComponent as IconBeach } from '../../icons/icon__beach.svg';
// import { ReactComponent as IconCannabis } from '../../icons/icon__cannabis.svg';
// import { ReactComponent as IconTree } from '../../icons/icon__tree.svg';
import { ReactComponent as IconTreasure } from '../../icons/icon__treasure.svg';

import styles from './map.module.scss';

import mapStyles from '../../utils/map-styling';
import mapMarkers from '../../utils/map-markers';

const Marker = ({ text, showSpan }) => (
  <div className={styles.pin}>
    <IconTreasure />
    {showSpan && (
      <motion.span
        initial="hidden"
        animate="visible"
        variants={variants}
        className={styles.pinInner}
      >
        <div>{text}</div>
      </motion.span>
    )}
  </div>
);

export default function Map({ height }) {

  const mapRef = useRef(null)

  const [showSpans, setShowSpans] = useState(false)
  const [random, setRandom] = useState(false)
  const [markers, setMarkers] = useState([]);

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map
  }

  const onMarkerClick = (e, { markerId, lat, lng }) => {
    mapRef.current.setCenter({ lat, lng })
  }

  const mapOptions = {
    "maxZoom": 20,
    "minZoom": 2,
    "mapTypeId": "roadmap",
    "mapTypeControl": false,
    "scaleControl": false,
    "streetViewControl": false,
    "rotateControl": true,
    "fullscreenControl": false,
    "zoomControl": true,
    "styles": mapStyles,
  }

  useEffect(() => {
    setMarkers(mapMarkers)
  }, []);

  useEffect(() => {
    const random = Math.floor(Math.random() * markers.length);
    const randomMarker = markers[random];
    setRandom(randomMarker);
  }, [markers]);

  const handleChange = (map) => {
    if (map && map.zoom > 11) {
      setShowSpans(true)
    } else {
      setShowSpans(false)
    }
  };

  if (!random) return null;

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      className={styles.container}
      key={`map`}
    >
      <AnimatePresence>
        <GoogleMap
          apiKey="AIzaSyCSY7jX1I-C8zRo9o26HMgmcpzkFmlot9w" // new key for cervezascamacho.com under startyparty
          defaultCenter={{
            lat: random.lat,
            lng: random.lng,
          }}
          defaultZoom={14}
          className={styles.outer}
          options={mapOptions}
          mapMinHeight={height}
          onGoogleApiLoaded={onGoogleApiLoaded}
          onChange={(map) => handleChange(map)}
          loadingContent={``}
        >
          {markers.map(({ lat, lng, name }, index) => (
            <Marker
              key={[index, showSpans]}
              lat={lat}
              lng={lng}
              text={name}
              markerId={name}
              showSpan={showSpans}
              onClick={onMarkerClick}
              // draggable={true}
              // onDragStart={(e, { latLng }) => {}}
              // onDrag={(e, { latLng }) => {}}
              // onDragEnd={(e, { latLng }) => {}}
            />
          ))}
        </GoogleMap>
      </AnimatePresence>
    </motion.div>
  );
}