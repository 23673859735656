import { useContext } from "react";
import YouTube from "react-youtube";
import { motion } from "framer-motion";

import styles from "./home.module.scss";
import { variants } from "../../utils/variants";

import { AppContext } from "../../AppContext";
import Map from "../map/map";
import Marquee from "./marquee";
import Article from "../article/article";

import { ReactComponent as Logo } from "../../icons/logo.svg";
import { ReactComponent as IconClock } from "../../icons/icon__time.svg";
import { ReactComponent as IconPin } from "../../icons/icon__pin.svg";
import { ReactComponent as IconFacebook } from "../../icons/icon__facebook.svg";
import { ReactComponent as IconInstagram } from "../../icons/icon__instagram.svg";
import { ReactComponent as IconUntapped } from "../../icons/icon__untapped.svg";

import packageJson from "../../../package.json";

export default function Home() {
  const { content, language } = useContext(AppContext);

  const sortNews = (news) => {
    return news.sort((a, b) => {
      return new Date(b._updatedAt) - new Date(a._updatedAt);
    });
  };

  if (!content.active) {
    return <div>Loading...</div>;
  }

  const { banner, blogHeader, hero, marquee, AfterMarqueeHero, HeroMap } =
    content.active.home ? content.active.home : {};

  const version = packageJson.version;

  const getYouTubeID = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return "error";
    }
  };

  const opts = {
    height: "640",
    width: "640",
    playerVars: {
      autoplay: 1,
      controls: 0,
      showinfo: 0,
      loop: 1, // Make sure loop is inside playerVars
      playlist:
        hero && hero.youtubeVideo
          ? getYouTubeID(hero.youtubeVideo.url)
          : "9L-1_cVU8PE", // Important for looping: specify the video ID here
      mute: 1,
      disablekb: 1,
      enablejsapi: 1,
      modestbranding: 1,
      playsinline: 1,
      origin: window.location.origin,
    },
  };

  const currentDate = new Date();
  const options = { weekday: "long", month: "long", day: "numeric" };
  const formattedDate = currentDate.toLocaleDateString(language, options);

  const cleanImageUrl = (url) => {
    const newRef = url
      .replace(/image-/g, "")
      .replace(/-png/g, ".png")
      .replace(/-jpg/g, ".jpg")
      .replace(/-jpeg/g, ".jpeg")
      .replace(/-webp/g, ".webp");
    const newUrl = `https://cdn.sanity.io/images/u4sc3w5j/production/${newRef}`;
    return newUrl;
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={variants}
      className={styles.container}
    >
      {/* Banner */}
      <div className={styles.banner}>
        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article}`}
        >
          <div className={styles.bannerLocation}>
            <IconPin />
            <p>{banner && banner.BannerLocationText}</p>
          </div>
          <div className={styles.bannerTime}>
            <p>
              <span>{banner && banner.BannerDaysOpenText}</span>
            </p>
            <span className={styles.bannerTimeOpen}>
              <IconClock />
              {banner && banner.BannerTimesOpenText}
            </span>
          </div>
        </motion.article>
      </div>

      {/* First Row - Intro */}
      <div className={styles.intro}>
        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding} ${styles.articleBorderRight}`}
        >
          <div className={styles.content}>
            <div className={styles.contentInner}>
              <Logo className={styles.logo} />
              <h2>{hero && hero.title}</h2>
              <p>{hero && hero.paragraph}</p>
            </div>
          </div>
        </motion.article>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={styles.article}
        >
          <div className={styles.contentVideo}>
            <div className={styles.contentInnerMediaVideo}>
              <YouTube
                videoId={
                  hero && hero.youtubeVideo
                    ? getYouTubeID(hero.youtubeVideo.url)
                    : "9L-1_cVU8PE"
                }
                className={styles.youtube}
                opts={opts}
                // onReady={handleVideoReady}
                // onEnd={endPlayback}
                // onError={endPlayback}
                // onStateChange={onStateChange}
              />
            </div>
          </div>
        </motion.article>
      </div>

      <div className={styles.header}>
        <h2>
          <img src={`/map/icon__map.png`} alt="placeholder" />
          {blogHeader && blogHeader.title}
        </h2>
        <h2 className={styles.hideForSmall}>
          {formattedDate}
          <img
            src={`/map/icon__map.png`}
            alt="placeholder"
            className={styles.flipped}
          />
        </h2>
      </div>

      {/* Second Row - News */}
      <div className={styles.news}>
        {content &&
          content.news.length &&
          sortNews(content.news)
            .slice(0, 4)
            .map((_, index) => (
              <Article news={content.news[index]} key={index} />
            ))}
      </div>

      {/* Marquee */}
      {marquee && (
        <div className={styles.marqueeContainer}>
          <Marquee text={marquee.text} />
        </div>
      )}

      {/* Third Row - Newest Beer */}
      <div className={styles.outer}>
        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articleBorderRight}`}
        >
          <div className={styles.content}>
            <div className={styles.contentInnerMedia}>
              <img
                src={
                  AfterMarqueeHero &&
                  cleanImageUrl(AfterMarqueeHero.image.asset._ref)
                }
                alt="placeholder"
              />
            </div>
          </div>
        </motion.article>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding}`}
        >
          <div className={styles.content}>
            <div className={`${styles.contentInner} ${styles.contentInnerBW}`}>
              <img src="/map/icon__island.png" alt="placeholder" />
              <h2>{AfterMarqueeHero && AfterMarqueeHero.title}</h2>
              <p>{AfterMarqueeHero && AfterMarqueeHero.paragraph}</p>
            </div>
          </div>
        </motion.article>
      </div>

      {/* Map */}
      <div className={`${styles.outer} ${styles.outerMap}`}>
        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding}`}
        >
          <div className={styles.content}>
            <div className={`${styles.contentInner} ${styles.contentInnerBW}`}>
              <img src="/map/icon__ship.png" alt="placeholder" />
              <h2>{HeroMap && HeroMap.title}</h2>
              <p>{HeroMap && HeroMap.paragraph}</p>
            </div>
          </div>
        </motion.article>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.articleMap} ${styles.articleBorderLeft}`}
        >
          <div className={styles.content}>
            <Map />
          </div>
        </motion.article>
      </div>

      {/* Footer */}
      <footer className={styles.footer}>
        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding}`}
        >
          <div className={styles.contentInner}>
            <Logo className={styles.logo} />
            <hr />
            <p>
              Paseo Real Marina, <br />
              Aguadilla, Puerto Rico 00603
            </p>
            <hr />
            <p>Hours: 03:00 – 10:00 pm</p>

            <div className={styles.social}>
              {content.footer.SocialMediaLinks && (
                <div className={styles.socialIcons}>
                  <a href={content.footer.SocialMediaLinks.facebook}>
                    <IconFacebook />
                  </a>
                  <a href={content.footer.SocialMediaLinks.instagram}>
                    <IconInstagram />
                  </a>
                  <a href={content.footer.SocialMediaLinks.untapped}>
                    <IconUntapped />
                  </a>
                </div>
              )}
            </div>

            <span className={styles.version}>
              Version <strong>{version}</strong>
            </span>
          </div>
        </motion.article>
      </footer>
    </motion.div>
  );
}
