import { useContext, useEffect, useState } from "react";
// import YouTube from 'react-youtube';
import Client from "shopify-buy";
import { motion } from "framer-motion";

import styles from "./store.module.scss";
import { variants } from "../../utils/variants";

import { AppContext } from "../../AppContext";
import Marquee from "./marquee";

import { ReactComponent as Logo } from "../../icons/logo.svg";
// import { ReactComponent as IconClock } from '../../icons/icon__time.svg';
// import { ReactComponent as IconPin } from '../../icons/icon__pin.svg';

import packageJson from "../../../package.json";

export default function Store() {
  const { content, language } = useContext(AppContext);
  const version = packageJson.version;

  const [products, setProducts] = useState([]);

  const currentDate = new Date();
  const options = { weekday: "long", month: "long", day: "numeric" };
  const formattedDate = currentDate.toLocaleDateString(language, options);

  useEffect(() => {
    if (products.length > 0) return;

    const client = Client.buildClient({
      domain: "65823d-c7.myshopify.com",
      storefrontAccessToken: "384278d051e94e3d79a0d11ebe6bd7f3", // access token
      // storefrontAccessToken: '6c9e2ecae4799ce1f96107d8749f4c6e',  // api key
      // storefrontAccessToken: 'd686c89110e4a1c522191533d9be3d07', // secret key
      language: language,
    });

    client.product.fetchAll().then((product) => {
      setProducts(product);
      // console.log(product)
    });
  }, [language, products]);

  if (!content.active) {
    return <div>Loading...</div>;
  }

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={variants}
      className={styles.container}
    >
      {/* Banner */}
      {/* <div className={styles.banner}>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article}`}
        >
          <div className={styles.bannerLocation}>
            <IconPin />
            <p>{content.active.banner && content.active.banner.BannerLocationText}</p>
          </div>
          <div className={styles.bannerTime}>
            <p>
              <span>{content.active.banner && content.active.banner.BannerDaysOpenText}</span>
            </p>
            <span className={styles.bannerTimeOpen}>
              <IconClock />
              {content.active.banner && content.active.banner.BannerTimesOpenText}
            </span>
          </div>
        </motion.article>

      </div> */}

      <div className={styles.header}>
        <h2>
          <img src={`/map/icon__map.png`} alt="placeholder" />
          {language === "en-US" ? "Store" : "Tienda"}
        </h2>
        <h2 className={styles.hideForSmall}>
          {formattedDate}
          <img
            src={`/map/icon__map.png`}
            alt="placeholder"
            className={styles.flipped}
          />
        </h2>
      </div>

      {/* Second Row - News */}
      <div className={styles.products}>
        {products &&
          products.map((product) => {
            return (
              <motion.article
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={variants}
                className={`${styles.article} ${styles.articleProduct} ${styles.articlePadding} ${styles.articleBorderRight}`}
                key={product.id}
              >
                <div className={styles.content}>
                  <a
                    href={`https://store.cervezascamacho.com/products/${product.handle}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={product.images[0].src} alt={product.title} />
                    <h3>{product.title}</h3>
                    <p>{product.description}</p>
                    <div className={styles.price}>
                      <span className={styles.priceNumber}>
                        ${Math.round(product.variants[0].price.amount)}
                      </span>
                      <button>Buy Now</button>
                    </div>
                  </a>
                </div>
              </motion.article>
            );
          })}
      </div>

      {/* Marquee */}
      {content.active.marquee && (
        <div className={styles.marqueeContainer}>
          <Marquee text={content.active.marquee.text} />
        </div>
      )}

      {/* Second Row - News */}
      {/* <div className={styles.products}>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding} ${styles.articleBorderRight}`}
        >
          <div className={styles.content}>
            <img src="/blog/image--1.jpg" alt="placeholder" />
            <h3>New Launch: <span>Beer Name Here</span></h3>
            <p>Galaxies globular star cluster muse about the carbon in our apple pies two ghostly white figures in coveralls and helmets are soflty dancing courage of our questions. Great turbulent clouds Orion's sword white dwarf hearts.</p>
          </div>
        </motion.article>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding} ${styles.articleBorderRight}`}
        >
          <div className={styles.content}>
            <img src="/blog/image--2.jpg" alt="placeholder" />
            <h3>Experience the Brew: <span>Join Us for a Tour</span></h3>
            <p>Galaxies globular star cluster muse about the carbon in our apple pies.</p>
          </div>
        </motion.article>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding}`}
        >
          <div className={styles.content}>
            <img src="/blog/image--3.jpg" alt="placeholder" />
            <h3>Celebrity X Loves X Beer</h3>
            <p>Galaxies globular star cluster muse about the carbon in our apple pies two ghostly white figures in coveralls and helmets are soflty dancing courage of our questions. Great turbulent clouds Orion's.</p>
          </div>
        </motion.article>

      </div> */}

      {/* Footer */}
      <footer className={styles.footer}>
        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding}`}
        >
          <div className={styles.contentInner}>
            <Logo className={styles.logo} />
            <p>
              Paseo Real Marina, <br />
              Aguadilla, Puerto Rico 00603
            </p>
            <p>Hours: 03:00 – 10:00 pm</p>
            <a href="https://marko.tech" target="_blank" rel="noreferrer">
              <span className={styles.version}>
                version <strong>{version}</strong>
              </span>
            </a>
          </div>
        </motion.article>
      </footer>
    </motion.div>
  );
}
