import React from 'react';

import AppContextProvider from './AppContext';
import Container from './Container';

function App() {

  return (
    <AppContextProvider>
      <Container />
    </AppContextProvider>
  );
}

export default App;
