import React, { useContext } from "react";
import { motion } from "framer-motion";

import { variants } from "../../utils/variants";

import styles from "./article.module.scss";

import { AppContext } from "../../AppContext";

export default function Article(props) {
  const { language } = useContext(AppContext);

  const { titleES, titleEN, descriptionES, descriptionEN, image, url } =
    props.news;

  const cleanImageUrl = (url) => {
    const newRef = url
      .replace(/image-/g, "")
      .replace(/-png/g, ".png")
      .replace(/-jpg/g, ".jpg")
      .replace(/-jpeg/g, ".jpeg")
      .replace(/-webp/g, ".webp");
    const newUrl = `https://cdn.sanity.io/images/u4sc3w5j/production/${newRef}`;
    return newUrl;
  };

  if (!props) {
    return <div>Loading...</div>;
  }

  return (
    <motion.article
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={variants}
      className={styles.article}
    >
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className={styles.content}>
          <img
            className={styles.image}
            src={cleanImageUrl(image.asset._ref)}
            alt={titleEN}
          />
          <h3>{language === "en-US" ? titleEN : titleES}</h3>
          <p>{language === "en-US" ? descriptionEN : descriptionES}</p>
        </div>
      </a>
    </motion.article>
  );
}
