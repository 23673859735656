import { useContext } from 'react';
// import YouTube from 'react-youtube';
import { motion } from 'framer-motion';

import styles from './about.module.scss';
import { variants } from '../../utils/variants';

import { AppContext } from '../../AppContext';
import Marquee from './marquee';

import { ReactComponent as Logo } from '../../icons/logo.svg';
// import { ReactComponent as IconClock } from '../../icons/icon__time.svg';
// import { ReactComponent as IconPin } from '../../icons/icon__pin.svg';

import packageJson from '../../../package.json';

export default function About() {

  const { content } = useContext(AppContext);

  const {
    hero,
    aboutUs,
    aboutUsDos,
    comapanies,
    marquee
  } = content.active.about ? content.active.about : {};

  const version = packageJson.version;

  // const currentDate = new Date();
  // const options = { weekday: 'long', month: 'long', day: 'numeric' };
  // const formattedDate = currentDate.toLocaleDateString(language, options);

  const cleanImageUrl = (url) => {
    const newRef = url.replace(/image-/g, '').replace(/-png/g, '.png').replace(/-jpg/g, '.jpg').replace(/-jpeg/g, '.jpeg').replace(/-webp/g, '.webp');
    const newUrl = `https://cdn.sanity.io/images/u4sc3w5j/production/${newRef}`;
    return newUrl;
  }

  if (!content.active) {
    return <div>Loading...</div>;
  }

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={variants}
      className={styles.container}
    >

      {/* First Row - Intro */}
      <div className={styles.intro}>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding} ${styles.articleBorderRight}`}
        >
          <div className={styles.content}>

            <div className={`${styles.contentInner} ${styles.contentInnerCentered} ${styles.contentInnerIntro}`}>
              <Logo className={styles.logo} />
              <p>{hero.paragraph ? hero.paragraph : ''}</p>
              {/* <h2>{content.active.hero && content.active.hero.title}</h2>
              <p>{content.active.hero && content.active.hero.paragraph}</p> */}
            </div>

          </div>
        </motion.article>

      </div>

      {/* Second Row - Companies */}
      <div className={styles.news}>

        {comapanies && comapanies.companies.map((company, index) => {
          return (
            <motion.article
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={variants}
              className={`${styles.article} ${styles.articlePadding} ${styles.articleBorderRight}`}
              key={index}
            >
              <div className={styles.content}>

                <div className={`${styles.contentInner} ${styles.contentInnerCentered}`}>
                  <img src={cleanImageUrl(company.logo.asset._ref)} alt={company.name} />
                  <h2>{company.name}</h2>
                  <p>{company.description}</p>
                </div>

              </div>
            </motion.article>
          )}
        )}

      </div>

      {/* Marquee */}
      {marquee && (
        <div className={styles.marqueeContainer}>
          <Marquee text={marquee.text} />
        </div>
      )}

      {/* Third Row - Newest Beer */}
      <div className={styles.outer}>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articleBorderRight}`}
        >
          <div className={styles.content}>

            <div className={styles.contentInnerMedia}>
              <img src={aboutUs && cleanImageUrl(aboutUs.image.asset._ref)} alt="placeholder" />
            </div>

          </div>
        </motion.article>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding}`}
        >
          <div className={styles.content}>

            <div className={`${styles.contentInner} ${styles.contentInnerBW}`}>
              <img
                src="/map/icon__island.png"
                alt="placeholder"
              />
              <h2>{aboutUs && aboutUs.title}</h2>
              <p>{aboutUs && aboutUs.paragraph}</p>
            </div>

          </div>
        </motion.article>

      </div>

      {/* Section */}
      <div className={`${styles.outer} ${styles.outerMap}`}>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding} ${styles.articleBorderRight}`}
        >
          <div className={styles.content}>

            <div className={`${styles.contentInner} ${styles.contentInnerBW}`}>
              <img
                src="/map/icon__ship.png"
                alt="placeholder"
              />
              <h2>{aboutUsDos && aboutUsDos.title}</h2>
              <p>{aboutUsDos && aboutUsDos.paragraph}</p>
            </div>

          </div>
        </motion.article>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article}`}
        >
          <div className={styles.content}>

            <div className={styles.contentInnerMedia}>
              <img src={aboutUsDos && cleanImageUrl(aboutUsDos.image.asset._ref)} alt="placeholder" />
            </div>

          </div>
        </motion.article>

      </div>

      {/* Footer */}
      <footer className={styles.footer}>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding}`}
        >
          <div className={styles.contentInner}>
            <Logo className={styles.logo} />
            <p>Paseo Real Marina, <br />Aguadilla, Puerto Rico 00603</p>
            <p>Hours: 03:00 – 10:00 pm</p>
            <a href="https://marko.tech" target="_blank" rel="noreferrer">
              <span className={styles.version}>version{" "}<strong>{version}</strong></span>
            </a>
          </div>
        </motion.article>

      </footer>

    </motion.div>
  );
}