import React, { createContext, useState, useEffect } from "react";

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [language, setLanguage] = useState("es-PR");
  const [view, setView] = useState("home");
  const [update, setUpdate] = useState(false);
  const [content, setContent] = useState({
    english: {},
    spanish: {},
    active: {},
    footer: {},
    news: {},
    menu: {},
  });

  // Function to update language and active content
  const updateLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    setContent((prevContent) => ({
      ...prevContent,
      active: newLanguage.includes("es")
        ? prevContent.spanish
        : prevContent.english,
    }));
  };

  useEffect(() => {
    fetch(
      "https://u4sc3w5j.api.sanity.io/v2021-10-21/data/query/production?query=*",
    )
      .then((response) => response.json())
      .then((data) => {
        const d = data.result;
        let content = {
          english: {},
          spanish: {},
          footer: {},
          menu: [],
          news: [],
        };

        d.forEach((item) => {
          switch (item.language) {
            case "en-US":
              content.english[item._type] = item;
              break;
            case "es-PR":
              content.spanish[item._type] = item;
              break;
            default:
              if (item._type === "footer") {
                content.footer = item;
              }
              if (item._type === "menu") {
                content.menu.push(item);
              }
              if (item._type === "news") {
                // reverse the order of the news
                // content.news.unshift(item);
                content.news.push(item);
              }
              break;
          }
        });

        setContent((prevState) => ({
          ...prevState,
          english: content.english,
          spanish: content.spanish,
          active: content.english, // Default active content
          footer: content.footer,
          news: content.news,
          menu: content.menu,
        }));
      })
      .catch((error) => console.log("error", error));

    updateLanguage(navigator.language);
  }, []);

  useEffect(() => {
    fetch(
      "https://u4sc3w5j.api.sanity.io/v2021-10-21/data/query/production?query=*",
    )
      .then((response) => response.json())
      .then((data) => {
        const d = data.result;
        let content = {
          english: {},
          spanish: {},
          footer: {},
          menu: [],
          news: [],
        };

        d.forEach((item) => {
          switch (item.language) {
            case "en-US":
              content.english[item._type] = item;
              break;
            case "es-PR":
              content.spanish[item._type] = item;
              break;
            default:
              if (item._type === "footer") {
                content.footer = item;
              }
              if (item._type === "menu") {
                content.menu.push(item);
              }
              if (item._type === "news") {
                // reverse the order of the news
                // content.news.unshift(item);
                content.news.push(item);
              }
              break;
          }
        });

        setContent((prevState) => ({
          ...prevState,
          english: content.english,
          spanish: content.spanish,
          active: content.english, // Default active content
          footer: content.footer,
          news: content.news,
          menu: content.menu,
        }));
      })
      .catch((error) => console.log("error", error));

    // updateLanguage(navigator.language);
  }, [update]);

  return (
    <AppContext.Provider
      value={{ language, content, updateLanguage, view, setView, update, setUpdate }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
export { AppContext };
