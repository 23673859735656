const staggerChildren = 0.25;
const bounce = 0.25;
const duration = 1;
const ease = [0.43, 0.13, 0.23, 0.96];
const transitionType = "spring" // "tween";
const delay = 0.125;

export const variantsFeed = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: staggerChildren,
      staggerDirection: -1,
      when: "beforeChildren",
      type: transitionType,
      duration: duration,
      delayChildren: delay,
    }
  },
  exit: {
    opacity: 0,
    duration: duration,
  },
};

export const variants = {
  hidden: {
    opacity: 0,
    // y: 15,
  },
  visible: {
    opacity: 1,
    // y: 0,
    transition: {
      staggerChildren: staggerChildren,
      when: "beforeChildren",
      ease: ease,
      type: transitionType,
      // mass: 0.3,
      duration: duration,
      // bounce: bounce,
      // delay: delay,
      // from: 5,
    }
  },
  exit: {
    opacity: 0,
  },
};

export const variantsTags = {
  hidden: {
    opacity: 0,
    x: "-50%",
    y: 100,
    transition: {
      delay: delay,
      duration: duration,
    }
  },
  visible: {
    opacity: 1,
    x: "-50%",
    y: 0,
    transition: {
      delay: delay,
      duration: duration,
    }
  }
};

export const variantsLoggedIn = {
  hidden: {
    y: 100,
    transition: {
      delay: delay,
      duration: duration,
    }
  },
  visible: {
    y: 0,
    transition: {
      duration: duration,
      ease: ease,
    }
  }
};

export const variantsNavIcon = {
  hidden: {
    y: 100,
    // maxWidth: 0,
    transition: {
      delay: delay,
      duration: duration,
    }
  },
  visible: {
    y: 0,
    // maxWidth: "45px",
    transition: {
      duration: duration,
      ease: ease,
    }
  }
};

export const variantsFadeBackground = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export const variantsPlayer = {
  hidden: {
    opacity: 0,
    x: "545px",
    maxWidth: 0
  },
  visible: {
    opacity: 1,
    x: 0,
    maxWidth: "272px"
    // delay: delay,
  },
};

export const variantsFade = {
  hidden: { opacity: 0, y: 0 },
  visible: {
    opacity: 1, y: 0,
    transition: {
      when: "beforeChildren",
      ease: ease,
      type: transitionType,
      // bounce: bounce,
      duration: duration,
      staggerChildren: staggerChildren,
    }
  },
};

export const variantsYT = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      // when: "beforeChildren",
      ease: ease,
      type: transitionType,
      bounce: bounce,
      duration: duration,
      // staggerChildren: staggerChildren,
    }
  },
  exit: { opacity: 0 }
};

export const variantsMediaSaved = {
  hidden: {
    // opacity: 0,
    // scale: 0.85
  },
  visible: {
    // opacity: 1,
    scale: 1,
    transition: {
      // when: "beforeChildren",
      from: 0.9,
      ease: ease,
      type: transitionType,
      bounce: bounce,
      duration: duration,
      // staggerChildren: staggerChildren,
    }
  },
  exit: { opacity: 0 }
};

export const noResultsVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ease: ease,
      duration: duration,
      type: transitionType,
      staggerChildren: staggerChildren,
    }
  },
};

export const variantsSearch = {
  hidden: { opacity: 0, marginTop: -15 },
  visible: { opacity: 1, marginTop: 0 },
  exit: { opacity: 0, marginTop: 15 }
};

export const variantsContainer = {
  hidden: {
    // aspectRatio: 0.001,
    transition: {
      duration: duration,
      type: "spring",
      bounce: bounce,
      ease: ease,
      delay: 0
    }
  },
  visible: {
    // aspectRatio: 16 / 9,
    transition: {
      duration: duration,
      type: "spring",
      bounce: bounce,
      ease: ease,
    }
  }
};